//#region Rely
import React, { useState } from "react";

//#endregion

//#region component
import MainFrame from "../components/main-frame";
import MainMenu from "../components/main-menu";
import ProjectCase from "../components/widgets/projectCase";
import ProjectTitle from "../components/widgets/projectTitle";
//#endregion

//#region option
import {
    projectNav,
} from "../staticConfMange/navMange.js"
import {
    buildCaseList,
    projectCaseList,
} from "../staticConfMange/projectMessage.js"
//#endregion


//#region cssRely
import {
    pageHeader,
    mainMenu,
} from "./index.module.css"

import {
    main,
    optionContainer
} from "./product.module.css"

import {
    projectCaseContainer
} from "./project.module.css"
//sass
//7/7sass的热重载会有问题，所以暂时还是不使用
// import("./project.scss");
//#endregion

/**
 * 
 * @returns {JSX.Element}
 */
export default function Porject({path}) {
    const [active, setActive] = useState(0);
    return (
        <MainFrame id="project-container">
            <header className={pageHeader}>
                <MainMenu  className={mainMenu} navPorps={projectNav} active={active} setActive={setActive} path={path} />
                <img alt="" style={{ width: '100%' }} src='/project/project-bg-1.jpg' />
            </header>
            {/* 带有.main的都是一个frame，后期看看Gatsby是否有路由插槽这样的东西，没有就这样子算了。有就拆分成组件，然后再显示 */}
            <div className={main}>
                <div id="a" className={optionContainer}>
                    <ProjectTitle {...{ subTitle: "项目案例", title: '市政公共设施项目' }} />
                    <div className={projectCaseContainer}>
                        {
                            projectCaseList.map((item,index) => <ProjectCase index={index} {...item} key={item.title} />)
                        }
                    </div>
                </div>
                <div id="b" className={optionContainer}>
                    <ProjectTitle {...{ subTitle: "项目案例", title: '建筑类项目' }} />
                    <div className={projectCaseContainer}>
                        {
                            buildCaseList.map(item => <ProjectCase {...item} key={item.title} />)
                        }
                    </div>
                </div>
            </div>
            {/* <div className={main} style={{ display: active === 1 ? "block" : 'none' }}>

            </div> */}
        </MainFrame>
    )
}